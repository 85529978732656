import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-icons/iconfont/material-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import { Amplify, Storage } from 'aws-amplify';
import './registerServiceWorker'
// eslint-disable-next-line
// @ts-ignore
import config from './aws-exports'

import shortkey from 'vue-shortkey'
Vue.use(shortkey);

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

// import VueMoment from 'vue-moment'
// import moment from 'vue-moment';
// Vue.use(VueMoment);

Vue.config.productionTip = false

Amplify.configure(config)

Storage.configure({ level: 'private' });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
